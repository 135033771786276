import './Tools.css';
import {useState,useEffect, useRef} from 'react';
import { Link, NavLink} from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { useDispatch, useSelector } from 'react-redux';
import { changetheme, devicetheme } from '../redux/actions';

export  function Top(){
    const [isMobile, setIsMobile] = useState(false);
    const [lastscrollpos, setlastscrollpos] = useState(0);
    const [fixmenu, setfixmenu] = useState(false);
    const[isMenuOPen, setMenuOpen] = useState(false);
    const [isthemechange, setthemechange] = useState(false);
    const [isdevicetheme, setdevicetheme] = useState(localStorage.getItem('theme') || false);
    const menuRef = useRef(null);
    const dispatch = useDispatch();
    const theme = useSelector(state => state.theme);
    useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth < 800);
        };
        window.addEventListener('resize', handleResize);
        handleResize(); // Call initially to set the state
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    useEffect(()=>{
        const handleScroll = ()=>{
            const currentscrollpos = window.pageYOffset;

            if(currentscrollpos < lastscrollpos) {
                setfixmenu(true);
            }else{
                setfixmenu(false)
            }
            setlastscrollpos(currentscrollpos)
        };
        window.addEventListener('scroll', handleScroll);
        return ()=> {
            window.removeEventListener('scroll', handleScroll);
        }
    })
    
    useEffect(()=>{
        const handleClickOutside = (event)=>{
            if(menuRef.current && !menuRef.current.contains(event.target)){
                setMenuOpen(false)
            }
        }
        document.addEventListener('mousedown',handleClickOutside)

        return ()=>{
            document.removeEventListener('mousedown',handleClickOutside)
        }
    },[menuRef])
    
    const toggleMenu = ()=>{
        setMenuOpen(!isMenuOPen)
    }

    const toggletheme = (type)=>{
        if(type === 'device'){
            dispatch(devicetheme())
            setdevicetheme(false)
        }else if(type === 'dark'){
            dispatch(changetheme('dark'))
            setdevicetheme(true)
        }else{
            dispatch(changetheme('light'))
            setdevicetheme(true)
        }
        setthemechange(false)
    }
   
  return(
      <div className='menucont'>
        <div className='menuBar' style={{position: `${fixmenu ? 'fixed' : 'absolute'}`, backgroundColor: `${theme === 'dark' ? '#30303e4d': '#f7f1f04d'}`}}>
        <h1  className='appname'><Link  id='appname' to='/'>am</Link></h1>
        {!isMobile && <nav role='navigation' aria-label='main-bar' className='menubarLinks'>
            <NavLink to="/" id='link' activeClassName={"active"}>home</NavLink>
            <NavLink to="/about" id='link' activeClassName={"active"}>about me</NavLink>
            <HashLink to="/#skills" scroll={(el)=> el.scrollIntoView({behavior: 'smooth'})} id='link'>expertise</HashLink>
            <HashLink to="/#projects" scroll={(el)=> el.scrollIntoView({behavior: 'smooth'})} id='link'>portfolio</HashLink>
            <NavLink to="/contact" id='link' activeClassName={"active"}>contact</NavLink>
            <button id='link' onClick={()=> setthemechange(true)}> <img src={`${process.env.PUBLIC_URL}/images/${theme === 'dark' ? 'light' : 'dark'}mode.png`} alt='tm'/> 
            {theme === 'dark' ? 'light' : 'dark'} theme</button>
        </nav>}

       {isMobile && <> <button id='themebut' onClick={()=> setthemechange(true)}> <img src={`${process.env.PUBLIC_URL}/images/${theme === 'dark' ? 'light' : 'dark'}mode.png`} alt='tm'/> 
       {theme === 'dark' ? 'light' : 'dark'} theme</button>
        {isMenuOPen ? <button id='menubut' onClick={toggleMenu}><img src={`${process.env.PUBLIC_URL}/images/${theme === 'dark' ? 'close' : 'closeBlack'}.png`} alt='X' /></button> : 
       <button id='menubut' onClick={toggleMenu}> <img src={`${process.env.PUBLIC_URL}/images/${theme ==='dark' ? 'menu' : 'menuBlack' }.png`} alt='menu'/></button>}</>}
       </div>

        {isMenuOPen &&(
        <div className={`dropdown ${isMenuOPen ? 'open': ''}`} style={{backgroundColor: `${theme === 'dark' ? '#30303e': '#f7f1f0'}`}} ref={menuRef}>
        <nav role='navigation' aria-label='main-dropdown' className="dropdown-menu">
            <NavLink to="/" id='link' onClick={toggleMenu} activeClassName={"active"}>home</NavLink>
            <NavLink to="/about" id='link' onClick={toggleMenu} activeClassName={"active"}>about me</NavLink>
            <HashLink to="/#skills" onClick={toggleMenu} scroll={(el)=> el.scrollIntoView({behavior: 'smooth'})} id='link'>expertise</HashLink>
            <HashLink to="/#projects" onClick={toggleMenu} scroll={(el)=> el.scrollIntoView({behavior: 'smooth'})} id='link'>portfolio</HashLink>
            <NavLink to="/contact" id='link' onClick={toggleMenu} activeClassName={"active"}>contact</NavLink>
            {/*<button id='link' onClick={()=> dispatch(changetheme())}> <img src={`${process.env.PUBLIC_URL}/images/${theme === 'dark' ? 'light' : 'dark'}mode.png`} alt='tm'/> 
            {theme === 'dark' ? 'light' : 'dark'} theme</button>*/}
            
        </nav>
        </div>
       )}
       {isthemechange && <div className='themeChange' style={{backgroundColor: `${theme === 'dark' ? '#30303e' : '#f7f1f0'}`}}>
        <img src={`${process.env.PUBLIC_URL}/images/${theme === 'dark' ? 'close' : 'closeBlack'}.png`} onClick={()=> setthemechange(false)} alt='X'/>
         <p onClick={()=> toggletheme('device')}> use device theme 
            {!isdevicetheme && <img src={`${process.env.PUBLIC_URL}/images/${theme === 'dark' ? 'tick' : 'tickBlack'}.png`} alt='active'/>}</p>
         <p onClick={()=> toggletheme('dark')}>  dark theme  
            {(isdevicetheme && theme === 'dark' ) && <img src={`${process.env.PUBLIC_URL}/images/tick.png`} alt='active'/>}</p>
         <p onClick={()=> toggletheme('light')}>light theme  
            {(isdevicetheme && theme === 'light' ) && <img src={`${process.env.PUBLIC_URL}/images/tickBlack.png`} alt='active'/>}</p>
       </div>}
       </div>
    );

  }


  export  function Footer(){
    const scrollToTop = ()=>{
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }
    return(
        <footer className='Footer'>
            <div className='footerlinks'>
            <Link to='/contact' className='footercontact'>Let's have a direct chat <img src={`${process.env.PUBLIC_URL}/images/forward.png`} alt='link'/></Link>
            <div className='social'>
                <h3>Follow me #austinemark</h3>
                <nav role='navigation' aria-label='footer-external-links' className='sociallinks'>
                    <a href='https://github.com/austinemark001'><img src={`${process.env.PUBLIC_URL}/images/githubFill.png`} alt='git'/></a>
                    <a href='https://www.facebook.com/profile.php?id=100081241973286'><img src={`${process.env.PUBLIC_URL}/images/facebookFill.png`} alt='fb'/></a>
                    <a href='https://www.instagram.com/mark.a.101?igsh=YzljYTk1ODg3Zg=='><img src={`${process.env.PUBLIC_URL}/images/instagramFill.png`} alt='ig'/></a>
                    <a href='https://www.linkedin.com/in/austine-mark-abb7282aa'><img src={`${process.env.PUBLIC_URL}/images/linkedinFill.png`} alt='in'/></a>
                    <a href='https://x.com/Austine19251417?t=XC13lUeb9F9VZrc50dxVqQ&s=09'><img src={`${process.env.PUBLIC_URL}/images/xFill.png`} alt='x'/></a>
                </nav>
            </div>
            </div>
           
                
            <p className='copywrite'>©2024- created with &#10084; by austine mark <img src={`${process.env.PUBLIC_URL}/images/up.png`} alt='scroll to top' onClick={scrollToTop}/></p>
            </footer> 
    )
}

// backgroundColor: `${theme === 'dark' ? '#30303e4d': '#f7f1f04d'}